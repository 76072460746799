<template>
    <div>
        <CDataTable
            :items="inventoryTransferDetail"
            :fields="fields"
            :items-per-page="10"
            hover
            pagination
            v-on:refresh="refresh"
            :loading="loading"
        >
            <template #transferCheckFlag="{item}">
            <td>
                <CIcon v-if="item.transferCheckFlag" class="green"
                        name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
            </td>
            </template>

        </CDataTable>
    </div>
</template>

<script>
    export default{
        name: "TransferDetailList",
        props:{
            params: Object,
        },
        computed:{
            inventoryTransferDetail: function() {
                return this.$store.getters.logisticInventoryTransferDetail
            },
            loading(){
                return this.$store.getters.inventoryLoading
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                fields: [
                    {key: 'id', label: 'Id',_style: 'font-size:12px'},
                    {key: 'skuId', label: 'SKU ID',_style: 'font-size:12px'},
                    {key: 'skuCode', label: 'SKU Kodu',_style: 'font-size:12px'},
                    {key: 'productName', label: 'Ürün Adı',_style: 'font-size:12px'},
                    {key: 'productTypeName', label: 'Ürün Tipi Adı',_style: 'font-size:12px'},
                    {key: 'amount', label: 'Adet',_style: 'font-size:12px'},
                    {key: 'barcode', label: 'Barkod',_style: 'font-size:12px'},
                    {key: 'logisticsCode', label: 'Ürün Kodu',_style: 'font-size:12px'},
                    {key: 'transferCheckFlag', label: 'Durum',_style: 'font-size:12px'},
                ],
            }
        },
        methods: {
            refresh: function(){
                this.$store.dispatch('logisticInventoryTransfer_detail', {transferId: this.params.id})
            }
        }
    }
</script>
<style scoped>
     .table-responsive{
        height: auto;
    }
</style>